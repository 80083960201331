body {
  overflow-y: scroll;
  overscroll-behavior: none;
}
/* hr with text in the middle -- hrstrike */
.hrstrike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}
.hrstrike > div {
  position: relative;
  display: inline-block;
}
.hrstrike > div:before,
.hrstrike > div:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background-color: #999;
}
.hrstrike > div:before {
  right: 100%;
  margin-right: 15px;
}
.hrstrike > div:after {
  left: 100%;
  margin-left: 15px;
}

.abs-centerXY {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.abs-centerY {
  position: absolute;
  left: 50%;
  transform: translateY(-50%);
}

.abs-centerX {
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
}

/// tables
table {
  border-spacing: 0;
  border-collapse: collapse;
}

table.alternating {
  tr:nth-child(even) {
    background-color: var(--alpha-minimize10);
    fill: var(--alpha-minimize10);
    // td:nth-child(1) {
    //   border-top-left-radius: 0.5rem;
    //   border-bottom-left-radius: 0.5rem;
    // }
    // td:nth-last-child(1) {
    //   border-top-right-radius: 0.5rem;
    //   border-bottom-right-radius: 0.5rem;
    // }
  }
}

.row-hover:hover {
  background-color: var(--alpha-minimize25);
  fill: var(--alpha-minimize25);
}

table.neutral th {
  background-color: var(--neutral);
}

table.clear th {
  background-color: transparent;
}
//
// table.list {
//   tr:not(.no-hover):hover {
//     cursor: pointer;
//     > td {
//       background-color: var(--alpha-minimize25);
//       fill: var(--alpha-minimize25);
//     }
//     // td:nth-child(1) {
//     //   border-top-left-radius: 0.5rem;
//     //   border-bottom-left-radius: 0.5rem;
//     // }
//     // td:nth-last-child(1) {
//     //   border-top-right-radius: 0.5rem;
//     //   border-bottom-right-radius: 0.5rem;
//     // }
//   }
//   th {
//     text-align: left;
//     // padding-left: 0.5rem;
//   }
//   // td {
//   //   padding: 0.25rem 0.5rem 0.25rem 0.5rem;
//   // }
// }
//
table.padded {
  th {
    padding-left: 0.5rem;
  }
  td {
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  }
}

.pill {
  border-radius: 1rem !important;
  border-width: 1px !important;
}

.hover-hilite:hover {
  background-color: var(--alpha-minimize05);
}

// .fader is the outer div, the elements you want faded are .fades
.fader {
  .fades {
    opacity: 0.1;
    transition: 0.3s;
    .hide,
    &.hide {
      opacity: 0;
    }
    .disappear,
    &.disappear {
      opacity: 0;
      transition: 0.3s;
      display: none;
    }
  }
  &:hover {
    .fades {
      opacity: 1;
      transition: 0.3s;
      display: inline;
    }
  }
}

.react-select__option {
  white-space: normal;
}

.theme-light button.clear.overlay:hover {
  background-color: var(--alpha-maximize25);
}


// used in Docs
.comma:not(:empty) ~ .comma:not(:empty):before {
  content: ', ';
}

.commas {
  :not(:nth-last-child(1)) {
    margin-right: 0.5rem;
    &:after {
      content: ',     ';
    }
  }
}

.commalist {
  .elem + .elem:last-child {
    &::before {
      content: ', and ';
    }
  }
  .elem:nth-of-type(n + 2):not(:last-child) {
    &::before {
      content: ', ';
    }
  }
}
