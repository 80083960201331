.activity {
  position: absolute;
  top: -3px;
  right: -3px;
  &.offset {
    right: -13px;
  }
  width: 10px;
  height: 10px;
  background-color: #ff4136;
  border-radius: 10px;
}
